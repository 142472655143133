import { atom } from "recoil";
import {
  AutocompleteMagicCardsRequest,
  CardChartRequest,
} from "modules/cards/model/card";
import {
  CardPriceCurrenciesEnum,
  CardPriceProviderEnum,
} from "modules/cards/model/card-price";

export const cardSearchReq = atom<AutocompleteMagicCardsRequest>({
  key: "cardSearchReq",
  default: {
    string: "",
    page: 0,
    pageSize: 10,
    foil: false,
    playable: false,
    setCode: "",
    format: "",
    rarity: "",
    types: [],
  },
});
export const cardChartReq = atom<CardChartRequest>({
  key: "cardChartReq",
  default: {
    cardId: "",
    currency: CardPriceCurrenciesEnum.EUR,
    provider: CardPriceProviderEnum.Cardmarket,
    finish: "",
  },
});
