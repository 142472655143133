const apiUrl =
  process.env.NEXT_PUBLIC_API_URL || "http://localhost:10001/api/v1/";

const setRoute = (subRoute: any) => `${apiUrl}${subRoute}`;

export const API_ROUTES = {
  AUTH: {
    LOGIN: setRoute("auth/login"),
    REGISTER: setRoute("auth/register"),
    FORGOT_PASSWORD: setRoute("auth/forgot-password"),
  },
  SETS: {
    GET_ALL: setRoute("card-sets"),
    GET_BY_CODE_NUMBER: (code: string, cardNumber: string) =>
      setRoute(`sets/${code}/cards/${cardNumber}`),
    PAGED: setRoute("sets"),
    SET: (code: string) => setRoute(`sets/${code}`),
  },
  COLLECTIONS: {
    GET_USER: setRoute("collections"),
    ADD_CARD: (id: string) => setRoute(`collections/${id}/cards`),
    ADD_CARD_BULK: (id: string) => setRoute(`collections/${id}/cards/bulk`),
    CREATE: setRoute("collections/create"),
    REMOVE_CARD: (id: string) => (cardId: string) =>
      setRoute(`collections/${id}/cards/${cardId}`),
    UPDATE_CARD: (id: string) => setRoute(`collections/${id}/update`),
    CARDS: (id: string) => setRoute(`collections/${id}/cards`),
    APPRAISE: (id: string) => setRoute(`collections/${id}/appraise`),
    SETS: (id: string) => setRoute(`collections/${id}/sets`),
    IMPORT: (id: string) => setRoute(`collections/${id}/imports`),
    IMPORTS_PAGED: (id: string) => setRoute(`collections/${id}/imports`),
    IMPORT_BY_ID: (id: string) => (impId: string) =>
      setRoute(`collections/${id}/imports/${impId}`),
    IMPORT_ROLLBACK: (id: string) => (impId: string) =>
      setRoute(`collections/${id}/imports/${impId}/rollback`),
  },
  DECKS: {
    GET_USER: setRoute("decks"),
    CREATE: setRoute("decks"),
    IMPORT_ARENA: (deckName: string) =>
      setRoute(`decks/import-arena/${deckName}`),
    GET_BY_ID: (id: string) => setRoute(`decks/${id}`),
    DELETE: (id: string) => setRoute(`decks/${id}`),
    ADD_CARD: (id: string) => setRoute(`decks/${id}/add-card`),
    REMOVE_CARD: (id: string) => setRoute(`decks/${id}/remove-card`),
    UPDATE_CARD: (id: string) => setRoute(`decks/${id}/update-card`),
    UPDATE: (id: string) => setRoute(`decks/${id}`),
    LATEST_PUBLIC: setRoute("decks/public/latest"),
    COPY: (id: string) => setRoute(`decks/${id}/copy`),
    OWNED_COUNTS: (id: string) => setRoute(`decks/${id}/owned-counts`),
  },
  MAGIC_CARDS: {
    PAGED: setRoute("magic-cards"),
    GET_BY_ID: (id: string) => setRoute(`magic-cards/${id}`),
    AUTOCOMPLETE: setRoute("magic-cards/autocomplete"),
    PRICES_SEARCH: setRoute("magic-cards/prices-search"),
    PRINTINGS: (id: string) => setRoute(`magic-cards/${id}/printings`),
    CHART: (id: string) => setRoute(`magic-cards/${id}/chart`),
    AVAILABLE_CHARTS: (id: string) => setRoute(`magic-cards/${id}/charts`),
  },
  TRADE_LISTS: {
    GET_USER: setRoute("trades/lists"),
    CREATE: setRoute("trades/lists"),
    GET_BY_ID: (id: string) => setRoute(`trades/lists/${id}`),
    DELETE: (id: string) => setRoute(`trades/lists/${id}`),
    ITEMS: (id: string) => setRoute(`trades/lists/${id}/items`),
    ADD_ITEM: (id: string) => setRoute(`trades/lists/${id}/items`),
    DELETE_ITEM: (id: string) => (itemId: string) =>
      setRoute(`trades/lists/${id}/items/${itemId}`),
    DELETE_BULK_ITEMS: (id: string) => setRoute(`trades/lists/${id}/items`),
    UPDATE_ITEM: (id: string) => (itemId: string) =>
      setRoute(`trades/lists/${id}/items/${itemId}`),
    APPRAISE: (id: string) => setRoute(`trades/lists/${id}/appraise`),
    TRADES: setRoute("trades"),
    TRADES_ITEMS: setRoute("trades/lists/items"),
    PUBLISH: (id: string) => setRoute(`trades/lists/${id}/publish`),
  },
  USERS: {
    SET_LGS: (id: string) => setRoute(`users/${id}/lgs`),
    GET_LGS: (id: string) => setRoute(`users/${id}/lgs`),
    GET: setRoute(`users`),
  },
  LGS: {
    GET: setRoute("lgs"),
    GET_LGS: (id: number) => setRoute(`lgs/${id}`),
    GET_CARDS: (id: string) => setRoute(`lgs/${id}/cards`),
    MATCHED_CARDS: (id: number) => setRoute(`lgs/${id}/matched-trades`),
    GET_USERS: (id: number) => setRoute(`lgs/${id}/users`),
  },
  VERSION: {
    GET: setRoute("version"),
  },
  METAGAME: {
    AVAILABLE_FORMATS: setRoute("metagame/available-formats"),
    GET_BY_FORMAT: (format: string) =>
      setRoute(`metagame/${format}/archetypes`),
    GET_DECKS_BY_ARCHETYPE: (format: string) => (archetype: string) =>
      setRoute(`metagame/${format}/archetypes/${archetype}`),
    GET_DECK: (format: string) => (archetypeSlug: string) => (id: string) =>
      setRoute(`metagame/${format}/archetypes/${archetypeSlug}/decks/${id}`),
  },
  CHATS: {
    BASE: setRoute("chats"),
    MESSAGES: (id: string) => setRoute(`chats/${id}/messages`),
    BY_ID: (id: string) => setRoute(`chats/${id}`),
  },
  NOTIFICATIONS: {
    BASE: setRoute("notifications"),
    MARK_READ: (id: string) => setRoute(`notifications/${id}/read`),
    MARK_SHOWN: (id: string) => setRoute(`notifications/${id}/shown`),
  },
};
