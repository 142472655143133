"use client";
import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";

export const OverflowTip = (props: {
  value: string;
  children: any;
  textRef: any;
  child?: any;
  placement?: string;
}) => {
  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  const compareSize = () => {
    if (props.textRef.current) {
      const compare =
        props.textRef.current.scrollWidth > props.textRef.current.clientWidth;
      setHover(compare);
    }
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    []
  );

  return (
    <Tooltip
      title={props.value}
      disableHoverListener={!hoverStatus}
      // @ts-ignore
      placement={props.placement ? props.placement : "bottom"}
    >
      {props.children}
    </Tooltip>
  );
};
