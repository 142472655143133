"use client";
import { Box, Button } from "@mui/material";
import React from "react";
import { CardPreviewSizing } from "utils/styles";
import { AutocompleteMagicCard, MagicCard } from "modules/cards/model/card";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import { flippableCardLayouts } from "modules/common/model/models";
import { CardPreviewLoading } from "modules/common/components/CardPreviewLoading";

interface Props {
  card: MagicCard | AutocompleteMagicCard;
  flipped: boolean;
  setFlipped: (flipped: boolean) => void;
  isFoil?: boolean;
  isTooltip?: boolean;
}

const FlipButton = ({
  setFlipped,
  flipped,
}: {
  setFlipped: (flipped: boolean) => void;
  flipped: boolean;
}) => (
  <Box
    display={"flex"}
    justifyContent={"center"}
    sx={{
      mt: 1,
    }}
  >
    <Button onClick={() => setFlipped(!flipped)} variant={"outlined"}>
      <CameraswitchIcon
        fontSize={"small"}
        sx={{
          mr: 1,
        }}
      />
      Flip
    </Button>
  </Box>
);

const CardPreview = ({
  card,
  isFoil,
  isBack,
}: {
  card: MagicCard;
  isFoil?: boolean;
  isBack?: boolean;
}) => <CardPreviewLoading card={card} foil={isFoil} isBack={isBack} />;

export const CardMediaImage = (props: Props) => {
  const { card, flipped, setFlipped, isFoil, isTooltip } = props;

  const flip = {
    transition: "transform 200ms",
    transformStyle: "preserve-3d",
    position: "absolute",
    backfaceVisibility: "hidden",
  };

  const showButton =
    "layout" in card &&
    !isTooltip &&
    flippableCardLayouts.includes(card.layout) &&
    card.otherFaces;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          ...CardPreviewSizing,
          display: "flex",
          position: "relative",
        }}
      >
        <Box
          sx={{
            transform: flipped ? "rotateY(180deg)" : "rotateY(0deg)",
            ...flip,
          }}
        >
          {/*<CardPreview card={card} isFoil={isFoil} isBack={false} />*/}
          <CardPreviewLoading card={card} foil={isFoil} isBack={false} />
        </Box>
        {"layout" in card &&
          flippableCardLayouts.includes(card.layout) &&
          card.otherFaces && (
            <Box
              sx={{
                transform: !flipped ? "rotateY(180deg)" : "rotateY(0deg)",
                ...flip,
              }}
            >
              {/*<CardPreview card={card} isFoil={isFoil} isBack={true} />*/}
              <CardPreviewLoading card={card} foil={isFoil} isBack={true} />
            </Box>
          )}
      </Box>
      {showButton && <FlipButton setFlipped={setFlipped} flipped={flipped} />}
    </Box>
  );
};
