import { FormatEnum } from "modules/decks/model/deck";
import {
  CardPriceCurrenciesEnum,
  CardPriceProviderEnum,
  EmbeddedCardPrice,
} from "modules/cards/model/card-price";
import { SortOrderEnum } from "modules/collections/details/model/collection";
import { CardSet } from "modules/cards/model/set";

export enum CardLanguageEnum {
  English = "English",
  AncientGreek = "Ancient Greek",
  Arabic = "Arabic",
  ChineseSimplified = "Chinese Simplified",
  ChineseTraditional = "Chinese Traditional",
  French = "French",
  German = "German",
  Hebrew = "Hebrew",
  Italian = "Italian",
  Japanese = "Japanese",
  Korean = "Korean",
  Latin = "Latin",
  Phyrexian = "Phyrexian",
  PortugueseBrazil = "Portuguese (Brazil)",
  Russian = "Russian",
  Sanskrit = "Sanskrit",
  Spanish = "Spanish",
}

export interface ForeignData {
  faceName: string;
  flavorText: string;
  language: CardLanguageEnum;
  multiverseId: number;
  name: string;
  text: string;
  type: string;
}

export interface Identifiers {
  cardKingdomFoilId: string;
  cardKingdomId: string;
  mcmId: string;
  mcmMetaId: string;
  mtgArenaId: string;
  mtg_json_v4_id: string;
  mtgoFoilId: string;
  mtgoId: string;
  multiverseID: string;
  scryfallId: string;
  scryfallIllustrationId: string;
  scryfallOracleId: string;
  tcgPlayerProductId: string;
}

export interface LeadershipSkills {
  brawl: boolean;
  commander: boolean;
  oathbreaker: boolean;
}

export enum LegalityStatus {
  Legal = "Legal",
  Banned = "Banned",
  Restricted = "Restricted",
}

export const defaultLegalities = [
  LegalityStatus.Legal,
  LegalityStatus.Banned,
  LegalityStatus.Restricted,
];

export type Legalities = {
  brawl: LegalityStatus;
  commander: LegalityStatus;
  duel: LegalityStatus;
  frontier: LegalityStatus;
  future: LegalityStatus;
  historic: LegalityStatus;
  legacy: LegalityStatus;
  modern: LegalityStatus;
  pauper: LegalityStatus;
  penny: LegalityStatus;
  pioneer: LegalityStatus;
  standard: LegalityStatus;
  vintage: LegalityStatus;
} & {
  [key in FormatEnum]: LegalityStatus;
};

export interface PurchaseUrls {
  cardKingdom: string;
  cardKingdomFoil: string;
  cardmarket: string;
  tcgPlayer: string;
}

export interface Ruling {
  date: string;
  text: string;
}

export enum ManaColorEnum {
  Black = "B",
  Red = "R",
  Green = "G",
  Blue = "U",
  White = "W",
  Colorless = "C",
  Devoid = "D",
}

export enum BorderColorEnum {
  Black = "black",
  Borderless = "borderless",
  Gold = "gold",
  Silver = "silver",
  White = "white",
}

export enum AvailabilityEnum {
  Paper = "paper",
  MTGO = "mtgo",
}

export enum FinishEnum {
  Etched = "etched",
  Foil = "foil",
  Glossy = "glossy",
  NonFoil = "nonfoil",
  Signed = "signed",
}

export enum FrameEffectsEnum {
  ColorShifted = "colorshifted",
  Companion = "companion",
  CompassLandDfc = "compasslanddfc",
  Devoid = "devoid",
  Draft = "draft",
  Etched = "etched",
  ExtendedArt = "extendedart",
  FullArt = "fullart",
  Inverted = "inverted",
  Legendary = "legendary",
  Lesson = "lesson",
  Miracle = "miracle",
  MoonEldraziDfc = "mooneldrazidfc",
  NyxTouched = "nyxtouched",
  OriginPwDfc = "originpwdfc",
  Showcase = "showcase",
  Snow = "snow",
  SunMoonDfc = "sunmoondfc",
  Tombstone = "tombstone",
  WaxingAndWaningMoonDfc = "waxingandwaningmoondfc",
}

export enum CardLayoutEnum {
  Adventure = "adventure",
  Aftermath = "aftermath",
  ArtSeries = "art_series",
  Augment = "augment",
  Class = "class",
  DoubleFacedToken = "double_faced_token",
  Emblem = "emblem",
  Flip = "flip",
  Host = "host",
  Leveler = "leveler",
  Meld = "meld",
  ModalDfc = "modal_dfc",
  Normal = "normal",
  Planar = "planar",
  ReversibleCard = "reversible_card",
  Saga = "saga",
  Scheme = "scheme",
  Split = "split",
  Token = "token",
  Transform = "transform",
  Vanguard = "vanguard",
}

export interface MagicCard {
  artist: string;
  asciiName: string;
  availability: AvailabilityEnum[];
  borderColor: BorderColorEnum;
  cardParts: string[];
  colorIdentity: ManaColorEnum[];
  colorIndicator: ManaColorEnum[];
  colors: ManaColorEnum[];
  edhrecRank: number;
  edhrecSaltiness: number;
  faceFlavorName: string;
  faceManaValue: number;
  faceName: string;
  finishes: FinishEnum[];
  flavorName: string;
  flavorText: string;
  foreignData: ForeignData[];
  frameEffects?: FrameEffectsEnum[];
  frameVersion: string;
  hand: string;
  hasAlternativeDeckLimit: boolean;
  hasContentWarning: boolean;
  identifiers: Identifiers;
  isAlternative: boolean;
  isFullArt: boolean;
  isFunny: boolean;
  isOnlineOnly: boolean;
  isOversized: boolean;
  isPromo: boolean;
  isRebalanced: boolean;
  isReprint: boolean;
  isReserved: boolean;
  isStarter: boolean;
  isStorySpotlight: boolean;
  isTextless: boolean;
  isTimeshifted: boolean;
  keywords: string[];
  language: CardLanguageEnum;
  layout: CardLayoutEnum;
  leadershipSkills: LeadershipSkills;
  legalities: Legalities;
  life: string;
  loyalty: string;
  manaCost: string;
  manaValue: number;
  name: string;
  number: string;
  originalPrintings: string[];
  originalReleaseDate: string;
  originalText: string;
  originalType: string;
  otherFaceIds: string[];
  power: string;
  printings?: string[];
  promoTypes: string[];
  purchaseUrls: PurchaseUrls;
  rarity: string;
  relatedCards: RelatedCards;
  rebalancedPrintings: string[];
  rulings: Ruling[];
  securityStamp: string[];
  setCode: string;
  side: string;
  signature: string;
  subsets: string[];
  subtypes: string[];
  supertypes: string[];
  text: string;
  toughness: string;
  type: string;
  types: string[];
  id: string;
  variations: string[];
  watermark: string;
  price?: EmbeddedCardPrice;
  set: CardSet;
  otherFaces?: MagicCard[];
}

export interface RelatedCards {
  reverseRelated?: string[];
  spellbook?: string[];
}

export interface CardFilterOptions {
  string: string;
  foil: boolean;
  playable: boolean;
  setCode: string;
  format: string;
  rarity: string;
  types: string[];
}

export interface AutocompleteMagicCardsRequest extends CardFilterOptions {
  pageSize: number;
  page: number;
}

export interface SearchMagicCardsRequest {
  fields: string[];
  pageSize: number;
  setCode: string;
  string: string;
}

export interface MagicCardPrintingsRequest {
  id: string;
  pageSize: number;
  page: number;
}

export interface AutocompleteMagicCard {
  isPromo: boolean;
  name: string;
  number: string;
  price: {
    default: number;
    foil: number;
  };
  scryfallId: string;
  setCode: string;
  id: string;
  manaCost: string;
  language: string;
  finishes: FinishEnum[];
}

export interface AutocompleteMagicCardsResponse {
  items: AutocompleteMagicCard[];
  hasMore: boolean;
}

export enum CardConditionEnum {
  _,
  Poor,
  Played,
  LightPlayed,
  Good,
  Excellent,
  NearMint,
  Mint,
}

export interface CardChartRequest {
  cardId: string;
  currency: CardPriceCurrenciesEnum;
  provider: CardPriceProviderEnum;
  finish: "" | "foil";
}

export interface CardAvailableChartsRequest {
  cardId: string;
  currency: CardPriceCurrenciesEnum;
  provider: CardPriceProviderEnum;
}

export interface CardChartResponse {
  cardId: string;
  currency: CardPriceCurrenciesEnum;
  provider: CardPriceProviderEnum;
  timestamps: string[];
  values: number[];
  averages: CardChartAverages;
}

export interface CardChartAverages {
  ma: CardPriceAveragePeriod;
  // ema: CardPriceAveragePeriod;
}

export interface CardPriceAveragePeriod {
  "7d": number[];
  "14d": number[];
  "30d": number[];
}

export enum CardSortOptionsEnum {
  Name = "name",
  Type = "types",
  Price = "price",
  Rarity = "rarity",
  ManaValue = "manaValue",
  Number = "number",
  PriceDeltaAbs = "priceDeltaAbs",
  PriceDeltaPercent = "priceDeltaPercent",
}

export enum PricePeriodEnum {
  ThreeDays = "3d",
  SevenDays = "7d",
  FourteenDays = "14d",
  ThirtyDays = "30d",
}

export interface CardsPagedRequest {
  page: number;
  perPage: number;
  sort: CardSortOptionsEnum;
  order: SortOrderEnum;
  pricePeriod: PricePeriodEnum;
  filter: CardFilterOptions;
}

export interface CardsPagedResponse {
  cards: MagicCard[];
  hasMore: boolean;
  total: number;
}
