export enum CardPriceCurrenciesEnum {
  EUR = "EUR",
  USD = "USD",
}

export enum CardPriceProviderEnum {
  Cardmarket = "cardmarket",
}

export interface SearchCardPricesRequest {
  cardId: string;
  currency: CardPriceCurrenciesEnum;
}

export interface PriceHistory {
  timestamp: Date;
  value: number;
}

export enum PricePeriodEnum {
  "3Day" = "3d",
  "7Day" = "7d",
  "14Day" = "14d",
  "30Day" = "30d",
  "60Day" = "60d",
}

export interface EmbeddedCardPrice {
  value: {
    default: number;
    foil: number;
  };
  deltas: {
    default?: {
      [key: string]: {
        abs: number;
        percent: number;
      };
    };
    foil?: {
      [key: string]: {
        abs: number;
        percent: number;
      };
    };
  };
}

export interface CardPrice {
  cardId: string;
  currency: string;
  foilHistory: PriceHistory[];
  normalHistory: PriceHistory[];
  provider: string;
}
