import { Box, Skeleton } from "@mui/material";
import React from "react";
import { manaCostToManaFontClasses } from "utils/utils";

interface Props {
  isLoading: boolean;
  manaCost?: string;
}

export const CardSkeleton = ({ manaCost, isLoading }: Props) => {
  const r = manaCostToManaFontClasses(manaCost || "").length;
  const manaSkeletons = [];
  for (let i = 0; i < r; i++) {
    manaSkeletons.push(
      <Skeleton
        key={i}
        variant={"circular"}
        animation={isLoading ? "wave" : false}
        width={16}
        height={16}
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "normal",
        ml: 1.5,
        mr: 1.5,
        mt: 0.5,
        maxHeight: "100%",
        flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          gap: 1,
        }}
      >
        <Skeleton
          width={"100%"}
          height={28}
          animation={isLoading ? "wave" : false}
        />
        {manaSkeletons.length ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row-reverse",
              flexShrink: 1,
              gap: 0.5,
            }}
          >
            {manaSkeletons}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
