import { CardStyle } from "modules/common/model/models";
import { SxProps, Theme } from "@mui/material";
import { CSSProperties } from "react";
import { ManaColorEnum } from "modules/cards/model/card";
import { FormatEnum } from "modules/decks/model/deck";
import { TradeListType } from "modules/tradeLists/model/tradeList";

export const BRAND_NAME = "MTG Guru";

export const IS_PRODUCTION = process.env.NODE_ENV === "production";

export const DeckLocationNameMap = {
  0: "Mainboard",
  1: "Sideboard",
  2: "Companion",
  3: "Commander",
  4: "Considering",
};
export const TradeListTypeNameMap = {
  1: "Wants",
  2: "Trade",
};
export const Formats = Object.values(FormatEnum);

export enum RaritiesEnum {
  Common = "common",
  Uncommon = "uncommon",
  Rare = "rare",
  Mythic = "mythic",
  Special = "special",
}

export enum CardTypesEnum {
  Artifact = "artifact",
  Creature = "creature",
  Enchantment = "enchantment",
  Instant = "instant",
  Land = "land",
  Planeswalker = "planeswalker",
  Sorcery = "sorcery",
}

export const Rarities = Object.values(RaritiesEnum);

export const TradeListTypesListDropDown = [
  { value: TradeListType.TRADES, label: "Trade" },
  { value: TradeListType.WANTS, label: "Wants" },
];
export const ManaColorNameMap = {
  W: "White",
  G: "Green",
  B: "Black",
  U: "Blue",
  R: "Red",
  D: "Devoid",
  C: "Colorless",
};

export const GroupingNameMap = {
  "0": "Type",
  "1": "Mana value",
  "2": "Color",
};
export const DisplayNameMap = {
  "0": "Table",
  "1": "Visual Stacks",
};

export const ManaColorChartColorMapping = () => {
  const colorMapping = new Map<ManaColorEnum, string>();
  colorMapping.set(ManaColorEnum.White, "#E8E6B4");
  colorMapping.set(ManaColorEnum.Black, "#291D2C");
  colorMapping.set(ManaColorEnum.Green, "#62A64F");
  colorMapping.set(ManaColorEnum.Red, "#D23C30");
  colorMapping.set(ManaColorEnum.Blue, "#3BB8E3");
  colorMapping.set(ManaColorEnum.Devoid, "#b0b0b0");
  colorMapping.set(ManaColorEnum.Colorless, "#BEB9B2");
  return colorMapping;
};

export const DeckChartShape = {
  bgcolor: "none",
  boxShadow: "none",
  width: "auto",
  // p: 1,
  flex: "0 0 auto",
  textAlign: "center",
  overflow: "visible",
  // borderRadius: "50%",
} as SxProps<Theme>;

export const DeckPieChartStyles: CardStyle = {
  //TODO:Fix on mobile
  card: DeckChartShape,
  chart: {
    height: 250,
    width: 250,
    marginLeft: "auto",
    marginRight: "auto",
  } as CSSProperties,
};

// Card Stack Heights
export const cardStackHeights = {
  cardHeight: 336,
  overlap: 300,
  headingHeight: 50,
};

export const cardLineActionStyles = {
  button: {
    width: 24,
    height: 24,
    color: "secondary.main",
  },
  icon: {
    width: 20,
    height: 20,
  },
  iconColor: {
    color: "black",
  },
  menuText: {
    fontSize: 14,
  },
  modalRoot: {
    background: "rgba(212,18,18,0.92)",
  },
  menuItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
};

export const DeckBarChartStyles: CardStyle = {
  //TODO:Fix on mobile
  card: DeckChartShape,
  chart: {
    height: 250,
    width: 250,
    marginLeft: "auto",
    marginRight: "auto",
  },
};

export const BasicLandNames = [
  "Plains",
  "Island",
  "Swamp",
  "Mountain",
  "Forest",
];

export const CommanderFormats = [
  FormatEnum.Commander,
  FormatEnum.Brawl,
  // FormatEnum.Oathbreaker,
  // FormatEnum.Planechase,
  // FormatEnum.PauperCommander,
  // FormatEnum.PennyDreadfulCommander,
  // FormatEnum.PioneerCommander,
  // FormatEnum.StandardCommander,
  // FormatEnum.VintageCommander,
];
