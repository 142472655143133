import { ServerError } from "modules/common/model/models";
import { useReset } from "modules/common/hooks/useReset";
import { useAuth } from "@clerk/nextjs";

export const useFetchWrapper = () => {
  const { getToken } = useAuth();
  const reset = useReset();

  return {
    get: request("GET"),
    post: request("POST"),
    put: request("PUT"),
    delete: request("DELETE"),
    submit: request("POST", "multipart/form-data"),
  };

  function request(
    method: string,
    cttype?: string
  ): (url: string, body: any) => Promise<any> {
    return async (url: string, body: any) => {
      let headers = await setHeaders(cttype);
      const opts: RequestInit = {
        method,
        headers,
      };

      if (body) {
        if (cttype) {
          opts.body = body;
        } else {
          opts.body = JSON.stringify(body);
        }
      }

      return fetch(url, opts).then(handleResponse);
    };
  }

  async function setHeaders(cttype?: string): Promise<Headers> {
    const headers = new Headers();
    const token = await getToken();
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    if (cttype) {
    } else {
      headers.set("Content-Type", "application/json");
    }
    return headers;
  }

  function handleResponse(response: any): Promise<any> {
    return response.text().then((text: string) => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
        const err = (data && data.message) || response.statusText;
        const result = {
          code: response.status,
          text: err,
        } as ServerError;
        return Promise.reject(result);
      }

      return Promise.resolve(data);
    });
  }
};
