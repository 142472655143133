import { useQuery } from "@tanstack/react-query";
import { useFetchWrapper } from "modules/common/hooks/useFetchWrapper";
import { API_ROUTES } from "utils/api-route.constants";
import { CardSets, ShortSet } from "modules/common/model/models";
import { QUERIES } from "modules/common/query";

export const useSets = () => {
  const fetch = useFetchWrapper();
  const query = useQuery<CardSets>({
    queryKey: [QUERIES.SETS],
    queryFn: () => fetchSets(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return {
    query: query,
    sortedSets: (): ShortSet[] => {
      if (query.data && query.isSuccess) {
        const res: ShortSet[] = [];
        for (let [key, value] of Object.entries(query.data)) {
          res.push({
            name: value.name,
            code: key,
            releaseDate: value.releaseDate,
          });
        }
        res.sort((a, b) => (a.name > b.name ? 1 : -1));
        return res;
      }
      return [];
    },
  };

  async function fetchSets() {
    const res = await fetch.get(API_ROUTES.SETS.GET_ALL, null);
    return res;
  }
};
