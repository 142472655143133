import { CSSProperties } from "react";
import { SxProps, Theme } from "@mui/material";
import {
  CardConditionEnum,
  CardLanguageEnum,
  FinishEnum,
} from "modules/cards/model/card";
import { DeckPagedItem } from "modules/decks/model/deck";
import { PagedTradeListItemsResponse } from "modules/tradeLists/model/tradeList";

export interface ServerError {
  code: number;
  text: string;
  message: string;
}

export interface LegalitiesPaletteColorOptions {
  legal?: string;
  banned: string;
  notLegal?: string;
  restricted: string;
}

export type ConditionsPaletteColorOptions = {
  [key in CardConditionEnum]: string;
};

export type CardSets = {
  [key: string]: {
    name: string;
    releaseDate: string;
  };
};

export interface ShortSet {
  name: string;
  code: string;
  releaseDate: string;
}

export interface CardStyle {
  chart?: CSSProperties;
  card?: SxProps<Theme>;
  cardContent?: SxProps<Theme>;
}

export enum CardGrouping {
  FirstType,
  ManaValue,
  Color,
}

export enum CardDisplay {
  Table,
  VisualStack,
}

export type CardLanguageCountryCodes = {
  [key in CardLanguageEnum]: string;
};

export const languageCountryCodes: CardLanguageCountryCodes = {
  [CardLanguageEnum.English]: "GB",
  [CardLanguageEnum.AncientGreek]: "",
  [CardLanguageEnum.Arabic]: "SA",
  [CardLanguageEnum.ChineseSimplified]: "CN",
  [CardLanguageEnum.ChineseTraditional]: "TW",
  [CardLanguageEnum.French]: "FR",
  [CardLanguageEnum.German]: "DE",
  [CardLanguageEnum.Hebrew]: "IL",
  [CardLanguageEnum.Italian]: "IT",
  [CardLanguageEnum.Japanese]: "JP",
  [CardLanguageEnum.Korean]: "KR",
  [CardLanguageEnum.Latin]: "",
  [CardLanguageEnum.Phyrexian]: "",
  [CardLanguageEnum.PortugueseBrazil]: "BR",
  [CardLanguageEnum.Russian]: "RU",
  [CardLanguageEnum.Sanskrit]: "",
  [CardLanguageEnum.Spanish]: "ES",
};

export type CardConditionStrings = {
  [key in CardConditionEnum]: string;
};

export const cardConditionStrings: CardConditionStrings = {
  [CardConditionEnum._]: "",
  [CardConditionEnum.Poor]: "PO",
  [CardConditionEnum.Played]: "PL",
  [CardConditionEnum.LightPlayed]: "LP",
  [CardConditionEnum.Good]: "GD",
  [CardConditionEnum.Excellent]: "EX",
  [CardConditionEnum.NearMint]: "NM",
  [CardConditionEnum.Mint]: "M",
};

export const cardConditionStringsFull: CardConditionStrings = {
  [CardConditionEnum._]: "",
  [CardConditionEnum.Poor]: "Poor",
  [CardConditionEnum.Played]: "Played",
  [CardConditionEnum.LightPlayed]: "Lightly played",
  [CardConditionEnum.Good]: "Good",
  [CardConditionEnum.Excellent]: "Excellent",
  [CardConditionEnum.NearMint]: "Near mint",
  [CardConditionEnum.Mint]: "Mint",
};

export interface ShortUser {
  id: string;
  username: string;
  email: string;
  preferredAvatar: string;
  lgs?: ShortLGS[];
}

export interface ShortLGS {
  wotcId: number;
  name: string;
  latitude: number;
  longitude: number;
  phoneNumber: string;
  emailAddress: string;
}

export const flippableCardLayouts = [
  "double_faced_token",
  "flip",
  "meld",
  "modal_dfc",
  "reversible_card",
  "transform",
];

export type FinishesNameMap = {
  [key in FinishEnum]: string;
};

export const finishesNameMap: FinishesNameMap = {
  [FinishEnum.Foil]: "Foil",
  [FinishEnum.NonFoil]: "Non-foil",
  [FinishEnum.Signed]: "Signed",
  [FinishEnum.Etched]: "Etched",
  [FinishEnum.Glossy]: "Glossy",
};

export interface UserProfilePageData {
  user: ShortUser;
  decks: DeckPagedItem[] | null;
  recentTrades: PagedTradeListItemsResponse | null;
  recentWants: PagedTradeListItemsResponse | null;
}
